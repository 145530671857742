import { ForeignKey } from "@/models/fields.js";
import BaseModel from "@/models/base-model.js";
import User from "@/models/user/user.js";

export default class Payment extends BaseModel {
    static describe () {
        return Object.assign({},
            BaseModel.describe(),
            {
                "user": {
                    type: ForeignKey(User)
                },
                "amount": {
                    type: Number
                },
                "description": {
                    type: String
                },
                "planned_charge_date": {
                    type: Date
                },
                "state": {
                    type: Number
                }
            }
        );
    }

    static get api_name () {
        return "user.payment";
    }
}

