import { ManyToMany } from "@/models/fields.js";
import BaseModel from "@/models/base-model.js";
import Payment from "@/models/user/payment.js";

export default class SepaMandate extends BaseModel {
    static describe () {
        return Object.assign({},
            BaseModel.describe(),
            {
                "payments": {
                    type: ManyToMany(Payment)
                },
                "expiration_date": {
                    type: Date
                }
            }
        );
    }

    static get api_name () {
        return "user.sepamandate";
    }
}

